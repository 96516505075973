import * as React from 'react';
import Layout from '../components/layout/layout';
import Biography from '../components/sections/biography';
import Blog from '../components/sections/blog';
import Hero from '../components/sections/hero';
import Publications from '../components/sections/publications';
import Showcase from '../components/sections/showcase';
import Subscribe from '../components/sections/subscribe';
import Vita from '../components/sections/vita';

// markup
const IndexPage = () => {
  return (
    <Layout title="Home">
      <Hero />
      <Biography />
      <Vita />
      <Showcase />
      <Blog />
      <Subscribe />
      <Publications />
    </Layout>
  );
};

export default IndexPage;
