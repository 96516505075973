import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Headline } from '../layout/typography';
import Card from '../card';

export default function Showcase() {
  const {
    allGraphCmsShowcase: { nodes: showcases },
  } = useStaticQuery(graphql`
    query Showcases {
      allGraphCmsShowcase(
        limit: 3
        sort: { fields: createdAt, order: DESC }
        filter: { stage: { eq: PUBLISHED } }
      ) {
        nodes {
          id
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 632
                  layout: FIXED
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
          url
          type
          project
          client
          color {
            hex
          }
        }
      }
    }
  `);

  return (
    <section id="showcase" className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg text-left max-w-5xl mx-auto">
          <Headline className="mb-6 text-7xl tracking-tight font-extrabold md:text-9xl">
            Code
            <br />
            &amp; Co.
          </Headline>
        </div>
        <div className="max-w-5xl mx-auto">
          <ul className="flex flex-wrap">
            {showcases.map((showcase, index) => {
              let classes = '';
              switch (index) {
                case 1:
                  classes = 'lg:-mt-16';
                  break;
                case 2:
                  classes = 'order-none lg:order-last lg:-mt-16';
                  break;
                default:
                  classes = '';
              }

              return (
                <li
                  key={showcase.id}
                  className={`w-full lg:w-1/2 mb-8 px-4 ${classes}`}
                >
                  <Card showcase={showcase} />
                </li>
              );
            })}
            <li className="w-full lg:w-1/2 mb-8">
              <div className="h-full w-full flex flex-col justify-center">
                <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
                  <span className="block font-semibold">There's more, but</span>
                  <span className="block font-extrabold">
                    these are my favorites.
                  </span>
                </h2>
                <p className="mt-8 text-xl text-gray-500 leading-8">
                  Over the last few years, I've been part of a pretty wide range
                  of projects - covering print design, both 2D and 3D animation,
                  web development as well as native app development.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
