import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Card({ showcase }) {
  const { client, color, image, project, type, url } = showcase;
  const hex = color ? color.hex : 'rgba(0,0,0,0)';

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="transition-all duration-500 ease-in-out box-border h-card w-full p-0 hover:p-2">
        <div className="relative h-full w-full px-8 py-12 overflow-hidden">
          <div
            className="transition-all duration-500 ease-in-out absolute top-0 left-0 bottom-0 right-0 scale-100 hover:scale-110"
            style={{ backgroundColor: hex }}
          >
            <GatsbyImage
              className="object-cover h-full"
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt=""
              placeholder="blurred"
            />
          </div>
          <div className="absolute">
            <h1 className="text-lg text-white font-bold uppercase">{client}</h1>
            <h2 className="text-3xl text-white font-bold">{project}</h2>
            <p className="text-lg text-white font-light">{type}</p>
          </div>
        </div>
      </div>
    </a>
  );
}
