import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { formatGCMSDateShort } from '../../utils/date';
import { TextLink } from '../layout/typography';

export default function Vita() {
  const {
    allGraphCmsExperience: { nodes: positions },
  } = useStaticQuery(graphql`
    query Experiences {
      allGraphCmsExperience(limit: 3, sort: { fields: endDate, order: DESC }) {
        nodes {
          id
          organization
          title
          startDate
          endDate
        }
      }
    }
  `);

  return (
    <section
      id="vita"
      className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap"
    >
      <div className="w-full lg:w-1/2 px-8 my-8 lg:my-0">
        <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
          <span className="block font-semibold">One step</span>
          <span className="block font-extrabold">at a time.</span>
        </h2>
        <p className="mt-8 mb-4 text-xl text-gray-500 leading-8">
          These are the most recent footprints of my professional life. See my{' '}
          <TextLink to="/vita">vita</TextLink> for a more complete list.
        </p>
      </div>
      <div className="w-full lg:w-1/2">
        <ul className="divide-y-2 divide-gray-200">
          {positions.map((position) => (
            <li key={position.id} className="py-4">
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <h3>{position.organization}</h3>
                  <div>
                    {formatGCMSDateShort(position.startDate)} -{' '}
                    {position.endDate
                      ? formatGCMSDateShort(position.endDate)
                      : 'today'}
                  </div>
                </div>
                <p className="text-md font-bold text-gray-900">
                  {position.title}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

