import React from 'react';
import { Headline, ExternalLink } from '../layout/typography';
import { StaticImage } from 'gatsby-plugin-image';

export default function Hero() {
  return (
    <>
      <section id="hero" className="lg:h-screen-4/5 w-full flex flex-wrap">
        <div className="w-full lg:w-1/2 h-full pt-32 px-2 lg:px-8 flex items-end lg:items-center lg:justify-end">
          <div className="w-full lg:w-auto text-center lg:text-left z-10">
            <Content />
          </div>
        </div>
        <div className="relative w-full lg:w-1/2 h-96 pt-12 lg:h-full lg:pt-32">
          <div className="absolute -top-1 -right-1 h-full z-0 hidden lg:block">
            <svg className="h-full w-auto" viewBox="0 0 600 600" version="1.1">
              <g>
                <path
                  d="M1.814,0.716L599.365,0.716L599.365,599.116L1.814,0.716Z"
                  fill="#7e42e1"
                />
              </g>
            </svg>
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <StaticImage
              className="w-96 h-96"
              src="../../images/hero.png"
              alt="A picture of Timothy"
              placeholder="blurred"
            />
          </div>
        </div>
      </section>
    </>
  );
}

function Content() {
  return (
    <>
      <Headline className="text-3xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
        Tech Enthusiast, <br />
        Music Afficionado, <br />
        Great At Googling
      </Headline>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        Hi there! I am Timothy, currently helping creative minds transform their
        ideas and visions into successful products. Have a fun project to collab
        on? Awesome,{' '}
        <ExternalLink href="mailto:hero@timothy.de">hit me up</ExternalLink>!
      </p>
      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div className="mt-3 sm:mt-0">
          <a
            href="#bio"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-blue hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
          >
            Scroll Down
          </a>
        </div>
        <div className="mt-3 sm:mt-0 sm:ml-3">
          <a
            href="#subscribe"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 md:py-4 md:text-lg md:px-10"
          >
            Subscribe
          </a>
        </div>
      </div>
    </>
  );
}
